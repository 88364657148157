'use strict';
import { __assign, __extends } from "tslib";
import './image-viewer.scss';
import $i18n from 'panda-i18n';
import React from 'react';
import ReactDOM from 'react-dom';
import Preview from './image-preview';
import classNames from 'classnames';
import { CnBox } from "../../cn-box";
import { getClientSize, errorImage } from '../utils';
import { safeCallFunction } from "../../cn-utils/helpers";
import { withNativeProps } from '@cainiaofe/cn-ui-common';
var CnImageViewer = /** @class */ (function (_super) {
    __extends(CnImageViewer, _super);
    function CnImageViewer(props) {
        var _this = _super.call(this, props) || this;
        _this.shouldExpandWidth = function (target) {
            if (!target)
                return false;
            var naturalWidth = target.naturalWidth, naturalHeight = target.naturalHeight;
            var _a = getClientSize(), clientWidth = _a.width, clientHeight = _a.height;
            return (_this.props.shouldPreviewExpand &&
                (naturalWidth / clientWidth) * clientHeight >= naturalHeight);
        };
        _this.onOpen = function (value, e) {
            _this.setState({
                visible: true,
                chooseUrl: value,
                shouldExpandWidth: _this.shouldExpandWidth(e === null || e === void 0 ? void 0 : e.target),
            });
        };
        _this.onError = function (e) {
            var _a = _this.props, onError = _a.onError, errorUrl = _a.errorUrl;
            e.target.src = errorUrl;
            onError && onError(e);
        };
        _this.renderImg = function (value, key) {
            var _a = _this.props, width = _a.width, height = _a.height;
            if (value) {
                return (React.createElement("img", { id: "cn-ui-image-viewer-image-".concat(key), key: key, onError: function (e) {
                        _this.onError(e);
                    }, onClick: function (e) { return _this.onOpen(value, e); }, className: 'cn-ui-image-viewer-img-item', src: value, alt: "", style: { width: width, height: height } }));
            }
            else {
                return (React.createElement("div", { id: "cn-ui-image-viewer-".concat(key), key: key, className: 'cn-ui-image-viewer-no-image-display', style: { width: width, height: height } }, $i18n.get({ id: 'NoPicture', dm: '无图片', ns: 'CnImageViewer' })));
            }
        };
        _this.onSwitch = function (direction, current) {
            if (current === void 0) { current = ''; }
            var src = _this.state.src;
            var len = Array.isArray(src) && src.length;
            var currentIndex = src.indexOf(current);
            if (len && len > 1 && currentIndex > -1) {
                switch (direction) {
                    case 'next': {
                        var newIndex = (currentIndex + 1) % len;
                        var shouldExpandWidth = _this.shouldExpandWidth(document.getElementById("cn-ui-image-viewer-image-".concat(newIndex)));
                        _this.setState({
                            chooseUrl: src[newIndex],
                            shouldExpandWidth: shouldExpandWidth,
                        });
                        break;
                    }
                    case 'prev': {
                        var newIndex = (currentIndex - 1 + len) % len;
                        var shouldExpandWidth = _this.shouldExpandWidth(document.getElementById("cn-ui-image-viewer-image-".concat(newIndex)));
                        _this.setState({
                            chooseUrl: src[newIndex],
                            shouldExpandWidth: shouldExpandWidth,
                        });
                        break;
                    }
                    default:
                }
            }
        };
        _this.onClose = function () {
            _this.setState({
                visible: false,
            });
            safeCallFunction(_this.props.onClose);
        };
        _this.state = {
            src: Array.isArray(props.src) ? props.src : [props.src],
            chooseUrl: '',
            visible: false,
            shouldExpandWidth: false,
        };
        return _this;
    }
    CnImageViewer.prototype.render = function () {
        var _this = this;
        var _a = this.props, className = _a.className, style = _a.style, shouldPreviewExpand = _a.shouldPreviewExpand, wrapperClassName = _a.wrapperClassName, errorUrl = _a.errorUrl, onError = _a.onError, hasDownload = _a.hasDownload, hideImageBox = _a.hideImageBox;
        var _b = this.state, src = _b.src, chooseUrl = _b.chooseUrl, visible = _b.visible, shouldExpandWidth = _b.shouldExpandWidth;
        return (React.createElement(React.Fragment, null,
            hideImageBox ? null : withNativeProps(this.props, React.createElement(CnBox, { direction: "row", spacing: 20, "data-name": "CnImageViewer", style: style, className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-image-viewer-image-player', className) }, src.map(function (item, index) {
                return _this.renderImg(item, index);
            }))),
            React.createElement(Preview, { ref: function (ref) {
                    _this.previewRef = ref;
                }, wrapperClassName: wrapperClassName, showSwitch: src.length > 1, visible: visible, chooseUrl: chooseUrl, onSwitch: this.onSwitch, onError: onError, onClose: this.onClose, shouldPreviewExpand: shouldPreviewExpand, shouldExpandWidth: shouldExpandWidth, errorUrl: errorUrl, hasDownload: hasDownload })));
    };
    CnImageViewer.displayName = 'CnImageViewer';
    CnImageViewer.defaultProps = {
        wrapperClassName: '',
        width: '100px',
        height: '100px',
        src: '',
        errorUrl: errorImage,
        shouldPreviewExpand: false,
        onError: function () { },
        onClose: function () { },
        hasDownload: true,
        hideImageBox: false,
    };
    CnImageViewer.getDerivedStateFromProps = function (nextProps, prevState) {
        var changeState = {};
        if (Array.isArray(nextProps.src) && nextProps.src !== prevState.src) {
            changeState.src = nextProps.src;
            changeState.chooseUrl = '';
        }
        if (!Array.isArray(nextProps.src) && nextProps.src !== prevState.src[0]) {
            changeState.src = [nextProps.src];
            changeState.chooseUrl = '';
        }
        if (Object.keys(changeState).length > 0) {
            return changeState;
        }
        return null;
    };
    CnImageViewer.open = function (config) {
        return open(config);
    };
    return CnImageViewer;
}(React.Component));
export { CnImageViewer };
var open = function (config) {
    var container = document.createElement('div');
    document.body.appendChild(container);
    var imageViewerInstance;
    var imageViewerRef;
    ReactDOM.render(React.createElement(CnImageViewer, __assign({}, config, { ref: function (ref) {
            imageViewerRef = ref;
        }, hideImageBox: true, onClose: function () {
            var _a;
            typeof config.onClose === 'function' && config.onClose();
            ReactDOM.unmountComponentAtNode(container);
            (_a = container.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(container);
        } })), container, function () {
        imageViewerInstance = imageViewerRef;
        var value = config === null || config === void 0 ? void 0 : config.value;
        if (!value && typeof (config === null || config === void 0 ? void 0 : config.src) === 'string') {
            value = config.src;
        }
        imageViewerInstance === null || imageViewerInstance === void 0 ? void 0 : imageViewerInstance.onOpen(value);
    });
    return {
        hide: function () {
            var _a;
            (_a = imageViewerInstance === null || imageViewerInstance === void 0 ? void 0 : imageViewerInstance.previewRef) === null || _a === void 0 ? void 0 : _a.onClose();
        },
    };
};
