import { __assign } from "tslib";
import * as React from 'react';
import NextTreeSelect from './tree-select';
import { useNextLocale } from "../../cn-utils";
var TreeSelect = React.forwardRef(function (props, ref) {
    var locale = useNextLocale('TreeSelect');
    return (React.createElement(NextTreeSelect, __assign({ locale: locale }, props, { ref: ref })));
});
TreeSelect.displayName = 'TreeSelect';
export default TreeSelect;
