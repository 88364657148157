import { __assign, __extends, __rest } from "tslib";
import * as React from 'react';
import { findDOMNode } from 'react-dom';
import numbro from 'numbro';
import classNames from 'classnames';
import { CnNumberPicker } from "../cn-number-picker";
import { CnCurrencySelect } from "../cn-currency-select";
import { dom } from "../../../_fusion/lib/util";
import currencyMap from './currency-map';
import { handleFormat, formatInputValue } from './util';
import { CnCurrencyAmountNumberPickerReadOnly } from './amount-select-read-only';
import './index.scss';
import { CnTooltip } from '../cn-tooltip';
/**
 * @deprecated
 * 该组件因数据合规问题已废弃，请使用 @alife/cn-domain-i18n 中的 CnCurrencyAmountNumberPicker 组件替换
 * 组件文档：https://cone.cainiao-inc.com/dsm/v3/@alife/cn-domain-i18n/CnCurrencyAmountNumberPicker
 */
var CnCurrencyAmountNumberPicker = /** @class */ (function (_super) {
    __extends(CnCurrencyAmountNumberPicker, _super);
    function CnCurrencyAmountNumberPicker(props) {
        var _this = this;
        var _a, _b, _c, _d;
        _this = _super.call(this, props) || this;
        _this.onCurrencySelectVisibleChange = function (newVisibleStatus) {
            if (newVisibleStatus) {
                _this.syncWidth();
            }
            _this.setState({
                currencySelectVisible: newVisibleStatus,
            });
        };
        _this.onNumberPickerFocusChange = function (newFocusState) {
            _this.setState({
                isNumberPickerFocus: newFocusState,
            });
        };
        _this.saveAmountSelectRef = function (ref) {
            // eslint-disable-next-line react/no-find-dom-node
            _this.amountSelectDOM = findDOMNode(ref);
        };
        /**
         * 获取当前精度。默认为2，若没有，则使用内置精度规范。
         * currency: 当前货币类型
         * dataSource: 货币数据源
         */
        _this.getPrecision = function (currency) {
            var tmpPrecision;
            if ('precision' in _this.props) {
                tmpPrecision = _this.props.precision;
            }
            // 若命中默认币种精度，则使用币种精度
            if (currency && currencyMap[currency]) {
                tmpPrecision = currencyMap[currency].precision;
            }
            return tmpPrecision;
        };
        // 货币类型更改
        _this.handleCurrencyChange = function (value, actionType, mixed) {
            var onChange = _this.props.onChange;
            var _a = _this.state, amount = _a.amount, currency = _a.currency;
            // 货币种类更改，对应的数据金额也需要更新
            var finalAmount = _this.handleRenderValue(amount, value);
            onChange &&
                onChange({
                    currency: value,
                    amount: finalAmount,
                    preAmount: amount,
                    preCurrency: currency,
                }, actionType, mixed);
            _this.setState({
                currency: value,
                amount: finalAmount,
            });
        };
        _this.handleAmountChange = function (val) {
            var onChange = _this.props.onChange;
            var currency = _this.state.currency;
            // 业务规定最多只能输入16位
            onChange &&
                onChange({
                    amount: val,
                    currency: currency,
                }, 'input', {});
            _this.setState({
                amount: val,
            });
        };
        /**
         * 将原始值根据规则转换为目标值，不是格式化的数据
         *
         * amount 输入值 123.456
         * tmpCurrency当前货币类型,不传入则使用state的类型 例如 'CNY'
         * dataSource 货币数据源
         * return  123.46
         */
        _this.handleRenderValue = function (amount, tmpCurrency) {
            // 当为异步赋值时，货币类型初始化不存在，需要进行兼容
            if (!tmpCurrency) {
                return amount;
            }
            var _a = _this.props, onChange = _a.onChange, format = _a.format;
            var currency = (_this.state || {}).currency;
            var precision = _this.getPrecision(tmpCurrency);
            var val = handleFormat(amount, precision, format);
            var finalValue = numbro.unformat(val);
            var finalRenderValue = "".concat(finalValue) === 'null' ? undefined : finalValue;
            // 当input发生改变时触发onChange事件
            if (amount !== finalRenderValue && onChange) {
                onChange({
                    amount: finalRenderValue,
                    currency: tmpCurrency || currency,
                    preAmount: amount,
                }, 'input', {});
            }
            return finalRenderValue;
        };
        _this.renderInput = function (inputEl) {
            var _a;
            var _b = _this.props, size = _b.size, i18nBundle = _b.i18nBundle, hasUnitTooltip = _b.hasUnitTooltip, unitTooltipProps = _b.unitTooltipProps;
            var _c = _this.state, amount = _c.amount, isNumberPickerFocus = _c.isNumberPickerFocus;
            if (hasUnitTooltip) {
                var numberFontSizeMap = {
                    xs: {
                        origin: 10,
                        fontSize: 12 / 2,
                    },
                    small: {
                        origin: 10,
                        fontSize: 12 / 2,
                    },
                    medium: {
                        origin: 10,
                        fontSize: 12 / 2,
                    },
                    large: {
                        origin: 6,
                        fontSize: 14 / 2,
                    },
                };
                var _d = size
                    ? numberFontSizeMap[size] || {}
                    : {}, _e = _d.origin, origin_1 = _e === void 0 ? 10 : _e, _f = _d.fontSize, fontSize = _f === void 0 ? 6 : _f;
                var offsetWidth = origin_1 + (((_a = String(amount)) === null || _a === void 0 ? void 0 : _a.length) || 0) * fontSize;
                return (React.createElement(CnTooltip, __assign({ followTrigger: true, trigger: inputEl, triggerType: [], className: "cn-ui-currency-amount-number-picker-unit-tooltip", popupProps: {
                        offset: [-offsetWidth, 0],
                        onVisibleChange: _this.onNumberPickerFocusChange,
                        // visible: true,
                        visible: amount && amount >= 1000 && isNumberPickerFocus,
                        align: 'tl br',
                        animation: false,
                    } }, unitTooltipProps), formatInputValue(amount, i18nBundle.currentLanguage)));
            }
            return inputEl;
        };
        var amount;
        var currency;
        if ('value' in props) {
            amount = (_a = props.value) === null || _a === void 0 ? void 0 : _a.amount;
            currency = (_b = props.value) === null || _b === void 0 ? void 0 : _b.currency;
        }
        else {
            amount = (_c = props.defaultValue) === null || _c === void 0 ? void 0 : _c.amount;
            currency = (_d = props.defaultValue) === null || _d === void 0 ? void 0 : _d.currency;
        }
        _this.state = {
            amount: amount,
            currency: currency,
            currencySelectVisible: false,
            popupWidth: 0,
            isNumberPickerFocus: false,
        };
        return _this;
    }
    CnCurrencyAmountNumberPicker.prototype.componentDidMount = function () {
        var _this = this;
        // overlay 还没有完成 mount，所以需要滞后同步宽度
        setTimeout(function () { return _this.syncWidth(); }, 0);
    };
    /**
     * Calculate and set width of popup menu
     * @protected
     */
    CnCurrencyAmountNumberPicker.prototype.syncWidth = function () {
        var popupWidth = this.state.popupWidth;
        var selectProps = this.props.selectProps;
        if ((selectProps === null || selectProps === void 0 ? void 0 : selectProps.popupStyle) && 'width' in selectProps.popupStyle) {
            return;
        }
        var width = dom.getStyle(this.amountSelectDOM, 'width');
        if (width && popupWidth !== width) {
            this.setState({
                popupWidth: width,
            });
        }
    };
    CnCurrencyAmountNumberPicker.prototype.render = function () {
        var _this = this;
        var _a = this.props, size = _a.size, className = _a.className, style = _a.style, disabled = _a.disabled, hasCurrencySelect = _a.hasCurrencySelect, hasHighlight = _a.hasHighlight, placeholder = _a.placeholder, i18nBundle = _a.i18nBundle, readOnly = _a.readOnly, readOnlyRender = _a.readOnlyRender, format = _a.format, numberPickerRender = _a.numberPickerRender, selectProps = _a.selectProps, state = _a.state, 
        // render 内未调用
        hasUnitTooltip = _a.hasUnitTooltip, unitTooltipProps = _a.unitTooltipProps, value = _a.value, onChange = _a.onChange, currencyDataSource = _a.currencyDataSource, onFocus = _a.onFocus, onBlur = _a.onBlur, numberPickerProps = __rest(_a, ["size", "className", "style", "disabled", "hasCurrencySelect", "hasHighlight", "placeholder", "i18nBundle", "readOnly", "readOnlyRender", "format", "numberPickerRender", "selectProps", "state", "hasUnitTooltip", "unitTooltipProps", "value", "onChange", "currencyDataSource", "onFocus", "onBlur"]);
        var _b = this.state, amount = _b.amount, currency = _b.currency, currencySelectVisible = _b.currencySelectVisible, popupWidth = _b.popupWidth;
        var i18nPlaceholder = placeholder || i18nBundle.placeholder;
        var precision = this.getPrecision(currency);
        var cls = classNames(CN_UI_HASH_CLASS_NAME, {
            'cn-ui-currency-amount-number-picker': true,
            'cn-ui-currency-amount-number-picker-with-currency': hasCurrencySelect,
            'amount-light': !!hasHighlight && !disabled,
        }, className);
        if (readOnly) {
            if (readOnlyRender) {
                return readOnlyRender(this.props);
            }
            return (React.createElement(CnCurrencyAmountNumberPickerReadOnly, { amount: amount, currency: currency, precision: precision, format: format, hasHighlight: hasHighlight, className: className }));
        }
        var numberPickerEl = (React.createElement(CnNumberPicker, __assign({ className: "cn-ui-currency-amount-number-picker-number-picker", value: amount, format: function (val) { return handleFormat(val, precision, format); }, onChange: function (val) { return _this.handleAmountChange(val); }, precision: precision, disabled: disabled, placeholder: i18nPlaceholder, inputRender: this.renderInput, onFocus: function (e) {
                _this.onNumberPickerFocusChange(true);
                onFocus && onFocus(e);
            }, onBlur: function (e) {
                _this.onNumberPickerFocusChange(false);
                onBlur && onBlur(e);
            }, size: size, maxLength: 16, state: state }, numberPickerProps)));
        if (numberPickerRender) {
            numberPickerEl = numberPickerRender(numberPickerEl);
        }
        return (React.createElement("div", { "data-name": "CnCurrencyAmountNumberPicker", ref: this.saveAmountSelectRef, className: cls, style: style, key: "main-span" },
            hasCurrencySelect && (React.createElement(CnCurrencySelect, __assign({ className: "cn-ui-currency-amount-number-picker-currency-select", dataSource: currencyDataSource, value: currency, showSearch: true, onChange: function (newValue, actionType, mixed) {
                    return _this.handleCurrencyChange(newValue, actionType, mixed);
                }, placeholder: i18nBundle.currencyPlaceholder, disabled: disabled, hasClear: false, autoWidth: false, visible: currencySelectVisible, onVisibleChange: this.onCurrencySelectVisibleChange, popupStyle: { width: popupWidth }, popupProps: { align: 'tl bl' }, popupClassName: "cn-ui-currency-select-popup cn-ui-currency-amount-number-picker-currency-popup", size: size, onFocus: onFocus, onBlur: onBlur, state: state }, selectProps))),
            numberPickerEl));
    };
    CnCurrencyAmountNumberPicker.displayName = 'CnCurrencyAmountNumberPicker';
    CnCurrencyAmountNumberPicker.defaultProps = {
        hasCurrencySelect: true,
        hasHighlight: true,
        precision: 2,
        hasUnitTooltip: true,
        i18nBundle: {},
    };
    CnCurrencyAmountNumberPicker.getDerivedStateFromProps = function (nextProps, prevState) {
        var _a, _b, _c, _d;
        var currency = prevState.currency, amount = prevState.amount;
        var changeState = {};
        if ('value' in nextProps && currency !== ((_a = nextProps.value) === null || _a === void 0 ? void 0 : _a.currency)) {
            changeState.currency = (_b = nextProps.value) === null || _b === void 0 ? void 0 : _b.currency;
        }
        if ('value' in nextProps && amount !== ((_c = nextProps.value) === null || _c === void 0 ? void 0 : _c.amount)) {
            changeState.amount = (_d = nextProps.value) === null || _d === void 0 ? void 0 : _d.amount;
        }
        return changeState;
    };
    return CnCurrencyAmountNumberPicker;
}(React.Component));
export { CnCurrencyAmountNumberPicker };
