import { __assign } from "tslib";
import * as React from 'react';
import { CnForm as MCnForm } from '@cainiaofe/cn-ui-m';
import { componentMap } from '@/form/component-map';
import { useLabelAlign } from './hooks/use-label-align';
import { useWrapperAlign } from './hooks/use-wrapper-align';
import { useLabelTextAlign } from './hooks/use-label-text-align';
export var CnForm = function (props) {
    var form = props.form, formProps = props.formProps, components = props.components, schema = props.schema, _a = props.formLayoutProps, formLayoutProps = _a === void 0 ? {} : _a, hasFooterSubmit = props.hasFooterSubmit, footerConfig = props.footerConfig, onSubmit = props.onSubmit, onReset = props.onReset;
    var labelAlign = useLabelAlign(formLayoutProps.labelAlign);
    var wrapperAlign = useWrapperAlign(formLayoutProps.wrapperAlign);
    var labelTextAlign = useLabelTextAlign(formLayoutProps.labelTextAlign);
    return (React.createElement(MCnForm, { form: form, formProps: formProps, schema: schema, components: __assign(__assign({}, componentMap), components), formLayoutProps: {
            size: formLayoutProps.size,
            labelAlign: labelAlign,
            wrapperAlign: wrapperAlign,
            labelTextAlign: labelTextAlign,
        }, hasFooterSubmit: hasFooterSubmit, footerConfig: footerConfig, onSubmit: onSubmit, onReset: onReset }, props.children));
};
CnForm.displayName = 'CnForm';
