import { __assign, __rest } from "tslib";
import * as React from 'react';
import { CnDialogFullpage } from '@cainiaofe/cn-ui-m';
import { CnCard } from '@/components/cn-card';
export var CnDrawer = function (props) {
    var children = props.children, noCard = props.noCard, embeddable = props.embeddable, restProps = __rest(props, ["children", "noCard", "embeddable"]);
    var hasCard = React.useMemo(function () {
        if (noCard) {
            return true;
        }
        if (embeddable) {
            return true;
        }
        var types = React.Children.map(children, function (item) {
            var _a;
            if (!React.isValidElement(item))
                return undefined;
            // @ts-ignore react 组件类型
            return (_a = item === null || item === void 0 ? void 0 : item.type) === null || _a === void 0 ? void 0 : _a.displayName;
        });
        if (!types)
            return false;
        return types.includes('CnCard') || types.includes('CnForm');
    }, [children]);
    return (React.createElement(CnDialogFullpage, __assign({ noCard: noCard }, restProps), hasCard ? children : React.createElement(CnCard, null, children)));
};
