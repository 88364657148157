import { __assign, __rest } from "tslib";
import * as React from 'react';
import { Button as NextButton } from "../../../_fusion/lib";
import { useAsyncLoading } from "../cn-utils/hooks";
import { HocBaseComponents, useCreatePluginInstance, } from "../cn-utils";
import classNames from 'classnames';
var CnButtonOrigin = React.forwardRef(function (props, ref) {
    var className = props.className, restProps = __rest(props, ["className"]);
    var wrapLoading = useAsyncLoading(props);
    return (React.createElement(NextButton, __assign({ className: classNames('cn-ui-button', className), "data-hottag": "cn-ui.cn-button", "data-name": "CnButton" }, restProps, wrapLoading, { ref: ref })));
});
export var CnButton = React.forwardRef(function (props, ref) {
    var _a;
    var pluginIns = useCreatePluginInstance('CnButton', props === null || props === void 0 ? void 0 : props.usePlugin);
    var plugins = (_a = pluginIns === null || pluginIns === void 0 ? void 0 : pluginIns.getPlugin) === null || _a === void 0 ? void 0 : _a.call(pluginIns);
    if (plugins.length === 0) {
        return React.createElement(CnButtonOrigin, __assign({}, props, { ref: ref }));
    }
    return HocBaseComponents(CnButtonOrigin, { props: props, plugins: plugins, ref: ref });
});
/**
 * @deprecated
 * 建议使用 SplitButtonGroup
 */
CnButton.Group = NextButton.Group;
CnButton.displayName = 'CnButton';
CnButton.defaultProps = {
    type: 'normal',
    // size: 'medium',
    icons: {},
    htmlType: 'button',
    component: 'button',
    loading: false,
    ghost: false,
    text: false,
    warning: false,
    disabled: false,
    onClick: function onClick() { },
};
export var Button = CnButton;
export var SplitButtonGroup = NextButton.Group;
