import { __assign, __rest } from "tslib";
import './index.scss';
import * as React from 'react';
import $i18n, { withI18n } from 'panda-i18n';
import classNames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CnIcon } from "../cn-icon";
import omit from 'lodash/omit';
import { CnMessage } from "../cn-message";
import locale from "../../locales";
import { CnReadOnlyContainer } from './cn-read-only-container';
// eslint-disable-next-line import/no-cycle
import tableCellRender from './render/table-cell-render';
import { FORM_EMPTY_CONTENT, cnReadOnlyRender } from '@cainiaofe/cn-ui-common';
// 单值渲染
var readOnlyItemRender = function (_type, value, emptyRender, dataSource) {
    if (cnReadOnlyRender.isEmpty(value))
        return emptyRender();
    var type = _type;
    if (!type)
        type = cnReadOnlyRender.getValueType(value);
    if (type === 'string')
        return cnReadOnlyRender.stringRender(value);
    if (type === 'number')
        return cnReadOnlyRender.numberRender(value);
    if (type === 'enum')
        return cnReadOnlyRender.enumRender(value, dataSource);
    if (type === 'boolean') {
        return cnReadOnlyRender.booleanRender({
            value: value,
            trueLabel: $i18n.get({ id: 'Yes', dm: '是', ns: 'CnReadOnly' }),
            falseLabel: $i18n.get({
                id: '31255237809541120.CNTM',
                dm: '否',
                ns: 'CnReadOnly',
            }),
        });
    }
    return $i18n.get({
        id: 'UnsupportedTypes',
        dm: '不支持的类型',
        ns: 'CnReadOnly',
    });
};
export var CnReadOnly = withI18n(function (props) {
    var emptyRender = props.emptyRender, type = props.type, _value = props.value, valueSeparator = props.valueSeparator, dataSource = props.dataSource, allowCopy = props.allowCopy, tableCellProps = props.tableCellProps, otherProps = __rest(props, ["emptyRender", "type", "value", "valueSeparator", "dataSource", "allowCopy", "tableCellProps"]);
    var value = Array.isArray(_value) && !_value.length ? undefined : _value;
    var content = null;
    if (type === 'tableCell') {
        return (React.createElement("div", __assign({ className: classNames('cn-ui-read-only', 'table-cell', props.className) }, omit(otherProps, ['showFolder']), { "data-name": "CnReadOnly" }), tableCellRender(tableCellProps)));
    }
    if (Array.isArray(value)) {
        var notEmptyValueArr = value.filter(function (valueItem) { return !cnReadOnlyRender.isEmpty(valueItem); });
        var renderValueArr = notEmptyValueArr.map(function (valueItem) {
            return readOnlyItemRender(type, valueItem, emptyRender, dataSource);
        });
        content = renderValueArr.join(valueSeparator);
    }
    else {
        content = readOnlyItemRender(type, value, emptyRender, dataSource);
    }
    return (React.createElement(CnReadOnlyContainer, __assign({ "data-name": "CnReadOnly", value: value }, otherProps),
        content,
        allowCopy && (React.createElement(CopyToClipboard, { className: "cn-ui-read-only-copy", text: content, onCopy: function () {
                return CnMessage.success($i18n.get({
                    id: 'ReplicationSuccessful',
                    dm: '复制成功',
                    ns: 'CnReadOnly',
                }));
            } },
            React.createElement(CnIcon, { type: "copy", size: "medium" })))));
}, {
    componentName: 'CnReadOnly',
    locale: locale,
});
CnReadOnly.defaultProps = {
    emptyRender: function () { return FORM_EMPTY_CONTENT; },
    valueSeparator: ',',
    line: 3,
    showFolder: false,
    allowCopy: false,
};
