import { __assign } from "tslib";
import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { CnDialog as MDialog, CnDialogFullpage } from '@cainiaofe/cn-ui-m';
import classNames from 'classnames';
import './index.scss';
export var CnDialog = React.forwardRef(function (props) {
    var className = props.className, size = props.size;
    if (size === 'small') {
        return (React.createElement(MDialog, __assign({}, props, { className: classNames('cn-oc-dialog', className) })));
    }
    return (React.createElement(CnDialogFullpage, __assign({}, props, { className: classNames('cn-oc-dialog', className) })));
});
CnDialog.displayName = 'CnDialog';
hoistNonReactStatics(CnDialog, MDialog);
