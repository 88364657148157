import { __assign, __rest } from "tslib";
import './radio.scss';
import * as React from 'react';
import { Radio as NextRadio } from "../../../../_fusion/lib";
import $i18n, { withI18n } from 'panda-i18n';
import { HocBaseComponents, Plugin, pluginManager, } from "../../cn-utils";
import { CnReadOnly } from "../../cn-read-only";
import locale from "../../../locales";
var renderPreview = function (value, readOnlyProps) {
    var showValue = value;
    if (typeof value === 'boolean') {
        showValue = value
            ? $i18n.get({ id: 'Yes', dm: '是', ns: 'CnRadio' })
            : $i18n.get({ id: '31255237809541120.CNTM', dm: '否', ns: 'CnRadio' });
    }
    return React.createElement(CnReadOnly, __assign({ value: showValue, type: "enum" }, readOnlyProps));
};
export var CnRadioBase = withI18n(React.forwardRef(function (props, ref) {
    var readOnly = props.readOnly, readOnlyProps = props.readOnlyProps, otherProps = __rest(props, ["readOnly", "readOnlyProps"]);
    return (React.createElement(NextRadio, __assign({ "data-name": "CnRadio", ref: ref, isPreview: readOnly, renderPreview: function (values) { return renderPreview(values, readOnlyProps); } }, otherProps)));
}), {
    locale: locale,
    componentName: 'CnRadio',
});
export var CnRadio = React.forwardRef(function (props, ref) {
    var plugin = React.useMemo(function () {
        var _plugin = new Plugin('CnRadio', pluginManager);
        _plugin.setLocalPlugin(props === null || props === void 0 ? void 0 : props.usePlugin);
        _plugin.setGlobalPlugin(pluginManager
            .getPluginsByComponentName('CnRadio')
            .map(function (item) { return item.method; }));
        return _plugin;
    }, []);
    var plugins = plugin.getPlugin();
    if (plugins.length === 0) {
        return React.createElement(CnRadioBase, __assign({}, props, { ref: ref }));
    }
    return HocBaseComponents(CnRadioBase, { props: props, plugins: plugins, ref: ref });
});
CnRadio.displayName = 'CnRadio';
CnRadio.defaultProps = {
    readOnlyProps: {
        type: 'enum',
    },
};
