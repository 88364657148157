import { __assign, __rest } from "tslib";
import './scss/input.scss';
import './scss/index.scss';
import * as React from 'react';
import cx from 'classnames';
import { getPopupContainer } from '@cainiaofe/cn-ui-common';
import { TimePicker2 as NextTimePicker2 } from "../fusion";
import omit from 'lodash/omit';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import compact from 'lodash/compact';
import { CnReadOnly } from "../cn-read-only";
import { useValueState } from "../cn-utils";
import { formatValue, getValueFromDayjs } from '../cn-date-picker-pro/util';
dayjs.extend(customParseFormat);
var RangePicker = NextTimePicker2.RangePicker;
var renderPreview = function (value, readOnlyProps, format) {
    var showValue = value;
    if (Array.isArray(value)) {
        showValue = compact(value);
        showValue = showValue.map(function (i) {
            var d = dayjs(i, format || 'HH:mm:ss');
            return d.isValid() ? d.format(format || 'HH:mm:ss') : '';
        });
    }
    else if (value) {
        var d = dayjs(value, format || 'HH:mm:ss');
        showValue = d.isValid() ? d.format(format || 'HH:mm:ss') : '';
    }
    return (React.createElement(CnReadOnly, __assign({ value: showValue, valueSeparator: " - ", type: "string" }, readOnlyProps)));
};
/**
 * fusion timepicker2.rangepicker对时间戳处理的问题
 */
function fixTimeRangeValue(value) {
    if (value && typeof value === 'number') {
        return dayjs(value);
    }
    return value;
}
function nextCompMap(Comp, displayName) {
    var Component = React.forwardRef(function (props, ref) {
        var _a;
        var readOnly = props.readOnly, readOnlyProps = props.readOnlyProps, popupContainer = props.popupContainer, className = props.className, outputFormat = props.outputFormat, state = props.state, otherProps = __rest(props, ["readOnly", "readOnlyProps", "popupContainer", "className", "outputFormat", "state"]);
        var _b = useValueState(props, undefined, {
            formatter: function (v) {
                return (Array.isArray(v)
                    ? v.map(function (i) { return fixTimeRangeValue(i); })
                    : fixTimeRangeValue(v));
            },
        }), value = _b[0], setValue = _b[1], isControlled = _b[2];
        var onChange = function (newValue, strVal) {
            if (!isControlled) {
                setValue(newValue);
            }
            var v = getValueFromDayjs(newValue);
            if (outputFormat) {
                v = formatValue(newValue, outputFormat);
            }
            props.onChange && props.onChange(v, strVal);
        };
        var onOk = function (newValue, strVal) {
            if (!isControlled) {
                setValue(value);
            }
            var v = getValueFromDayjs(newValue);
            if (outputFormat) {
                v = formatValue(newValue, outputFormat);
            }
            props.onOk && props.onOk(v, strVal);
        };
        if (props.readOnly) {
            return renderPreview(value, readOnlyProps, props.format);
        }
        var coverProps = {
            value: value,
            onChange: onChange,
            onOk: onOk,
            disabledHours: function (index) {
                return (props.disabledHours && props.disabledHours(index, value));
            },
            disabledMinutes: function (index) {
                return (props.disabledMinutes && props.disabledMinutes(index, value));
            },
            disabledSeconds: function (index) {
                return (props.disabledSeconds && props.disabledSeconds(index, value));
            },
        };
        if (displayName === 'CnTimePicker2') {
            coverProps = omit(coverProps, 'onOk');
        }
        return (React.createElement(Comp, __assign({ "data-name": "CnTimePickerPro", className: cx((_a = {
                    'cn-ui-time-picker-pro': true
                },
                _a["cn-ui-time-picker-pro-".concat(state)] = true,
                _a), className), ref: ref, popupContainer: getPopupContainer(popupContainer), state: state }, otherProps, coverProps)));
    });
    Component.displayName = displayName;
    return Component;
}
/**
 * @deprecated
 * 使用 CnTimePickerPro
 */
export var CnTimePicker2 = nextCompMap(NextTimePicker2, 'CnTimePicker2');
/**
 * @deprecated
 * 使用 CnRangeTimePickerPro
 */
export var CnRangeTimePicker2 = nextCompMap(RangePicker, 'CnRangeTimePicker2');
export var CnTimePickerPro = CnTimePicker2;
export var CnRangeTimePickerPro = CnRangeTimePicker2;
