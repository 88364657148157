import { __assign, __rest } from "tslib";
// 第三方依赖
import * as React from 'react';
import { unstable_batchedUpdates as batchedUpdates } from 'react-dom';
import { useMemo, useRef, useCallback } from 'react';
import $i18n from 'panda-i18n';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';
// 其他组件
import { CnPagination } from "../../cn-pagination";
import { CnBaseTable } from "../../cn-base-table";
import { useNextLocale, HocBaseComponents, useCreatePluginInstance, } from "../../cn-utils";
import { CnLoading } from "../../cn-loading";
import { obj } from "../../../../_fusion/lib/util";
import { nativePropsReg } from '@cainiaofe/cn-ui-common';
import { isNumber, normalizeProps, handleMerge, getWrapperStyle, expandCell, getFinalStyle, setInnerInfo, } from './utils';
// 当前目录的相对依赖
import Toolbar from './toolbar';
import { useColumnsResize, useGetTreeConfig, usePage, useScrollRecord, useSelect, useDelegateHub, useRemote, } from './hooks';
import { config, cellFormat, registerFormat, getCellFormat } from './config';
import GlobalContext from './context';
import './index.scss';
function CnTableOrigin(props) {
    var _a;
    var _b = normalizeProps(props), 
    // showSelect = false,
    showSelect = _b.showSelect, // 是否显示选择框, 默认为 false
    selectType = _b.selectType, remote = _b.remote, maxHeight = _b.maxHeight, _c = _b.paging, paging = _c === void 0 ? false : _c, _d = _b.pagingDirection, pagingDirection = _d === void 0 ? 'right' : _d, pageProps = _b.pageProps, onPageChange = _b.onPageChange, onSelectChange = _b.onSelectChange, toolbar = _b.toolbar, _e = _b.fullContainer, fullContainer = _e === void 0 ? true : _e, rowSelection = _b.rowSelection, _f = _b.columnsAutoWidth, columnsAutoWidth = _f === void 0 ? true : _f, onSort = _b.onSort, sortCustom = _b.sortCustom, tree = _b.tree, emptyContent = _b.emptyContent, cellOverflowEllipsis = _b.cellOverflowEllipsis, // 单元格是否溢出显示省略号, 默认为 false
    slot = _b.slot, className = _b.className, tableClassName = _b.tableClassName, defaultPageSize = _b.defaultPageSize, // 默认每页数量, 默认为 globalConfig.defaultPageSize
    format = _b.format, rest = __rest(_b, ["showSelect", "selectType", "remote", "maxHeight", "paging", "pagingDirection", "pageProps", "onPageChange", "onSelectChange", "toolbar", "fullContainer", "rowSelection", "columnsAutoWidth", "onSort", "sortCustom", "tree", "emptyContent", "cellOverflowEllipsis", "slot", "className", "tableClassName", "defaultPageSize", "format"]);
    var global = useMemo(function () {
        return {
            tableProps: props,
        };
    }, []);
    var delegateHub = useDelegateHub(props);
    var dataSource = delegateHub.dataSource, setDataSource = delegateHub.setDataSource, primaryKey = delegateHub.primaryKey, setPrimaryKey = delegateHub.setPrimaryKey, sorts = delegateHub.sorts, setSorts = delegateHub.setSorts, loading = delegateHub.loading, setLoading = delegateHub.setLoading, size = delegateHub.size, setSize = delegateHub.setSize, fullScreen = delegateHub.fullScreen, setFullScreen = delegateHub.setFullScreen, zebra = delegateHub.zebra, setZebra = delegateHub.setZebra, columns = delegateHub.columns, setColumns = delegateHub.setColumns, openKeys = delegateHub.openKeys, setOpenKeys = delegateHub.setOpenKeys;
    var columnsResize = useColumnsResize(props, columns, setColumns);
    rest.columnResize = columnsResize;
    var _g = useSelect(props), hasSelect = _g.hasSelect, storeSelectInfo = _g.storeSelectInfo, selectKeys = _g.selectKeys, setSelectKeys = _g.setSelectKeys;
    var _h = useGetTreeConfig(props, setLoading), treeConfig = _h.treeConfig, treeKeys = _h.treeKeys, setTreeKeys = _h.setTreeKeys;
    var pageRenderInfo = usePage(props);
    // 处理滚动穿透问题、并记录滚动距离。
    useScrollRecord(fullScreen);
    // 保存排序等内置参 数
    var innerParams = useRef({});
    var clear = function () {
        // @ts-ignore
        setSelectKeys([], []);
        setOpenKeys([]);
        setSorts([]);
        innerParams.current = {};
        setTreeKeys([]);
    };
    var fetchCall = useRemote({
        pageRenderInfo: pageRenderInfo,
        setLoading: setLoading,
        remote: remote,
        innerParams: innerParams,
        innerInfo: Object.assign({}, delegateHub, {
            // 内部信息
            selectKeys: selectKeys,
            setSelectKeys: setSelectKeys,
            treeKeys: treeKeys,
            setTreeKeys: setTreeKeys,
            pageInfo: pageRenderInfo,
            selectInfo: storeSelectInfo,
        }),
    }, function (result, clearDataType) {
        // 请求成功后的回调函数
        batchedUpdates(function () {
            var _a, _b;
            var _c = result || {}, sourceTableColumns = _c.tableColumns, // 表格列信息
            tableData = _c.tableData, // 表格数据
            _paging = _c.paging, // 分页信息
            _primaryKey = _c.primaryKey;
            var tableColumns = sourceTableColumns;
            // @ts-ignore
            var hasRemoteColumns = isEmpty(props.columns) && !isEmpty(tableColumns);
            if (hasRemoteColumns) {
                // 远程数据源中有列信息
                setColumns(tableColumns); // 更新列信息
            }
            var emptyColumns = !props.columns &&
                isEmpty(tableColumns) &&
                isPlainObject(tableData === null || tableData === void 0 ? void 0 : tableData[0]);
            if (emptyColumns) {
                // 远程数据源中没有列信息
                // @ts-ignore
                var data_1 = ((tableData === null || tableData === void 0 ? void 0 : tableData[0]) || {});
                tableColumns = Object.keys(data_1)
                    .filter(function (key) { return isString(data_1[key]) || isNumber(data_1[key]); })
                    .map(function (key) { return ({
                    name: key,
                    dataIndex: key,
                }); });
                setColumns(tableColumns); // 生成列信息
            }
            if (Array.isArray(tableData)) {
                // 更新表格数据
                setDataSource(tableData);
            }
            else {
                setDataSource([]);
            }
            var total = (_b = (_a = _paging === null || _paging === void 0 ? void 0 : _paging.totalCount) !== null && _a !== void 0 ? _a : _paging === null || _paging === void 0 ? void 0 : _paging.total) !== null && _b !== void 0 ? _b : 0;
            if (!isNil(total)) {
                // 更新总数
                pageRenderInfo.setTotal(total);
            }
            if (!isNil(_paging === null || _paging === void 0 ? void 0 : _paging.pageSize) &&
                (_paging === null || _paging === void 0 ? void 0 : _paging.pageSize) !== pageRenderInfo.pageSize) {
                // 更新每页数量
                pageRenderInfo.setPageSize(_paging === null || _paging === void 0 ? void 0 : _paging.pageSize);
            }
            // 处理 clearDataType 相关逻辑，进行刷新后的信息清除
            if (clearDataType === 'refresh') {
                // 清空数据
                pageRenderInfo.setCurrent(1);
                // 清除若干状态
                clear();
            }
            if (clearDataType === 'refreshExceptPaging') {
                // 清除若干状态
                clear();
            }
            if (isString(_primaryKey)) {
                // 更新主键
                // @ts-ignore
                setPrimaryKey(_paging === null || _paging === void 0 ? void 0 : _paging.primaryKey);
            }
            // 批量更新
            isFunction(remote === null || remote === void 0 ? void 0 : remote.dataChange) && (remote === null || remote === void 0 ? void 0 : remote.dataChange(result)); // 数据变化回调
        });
    });
    /**
     * 设置中间排序
     * @param {string} code - 排序字段
     * @param {string} order - 排序方式
     * @param {object} sort - 排序对象
     * @returns {boolean} - 是否使用 ascii 排序
     */
    var middleSort = function (code, order, sort) {
        var _a;
        // 设置排序
        // @ts-ignore
        setSorts(code && order ? [{ code: code, order: order }] : []);
        if (isFunction(onSort)) {
            // 执行 onSort 回调函数
            // @ts-ignore
            return onSort(code, order, sort);
        }
        // 判断是否使用 ascii 排序
        var asciiSort = (_a = remote === null || remote === void 0 ? void 0 : remote.asciiSort) !== null && _a !== void 0 ? _a : true;
        // 远程排序
        if (!asciiSort) {
            // 更新内部参数中的排序列表
            if (code || order) {
                innerParams.current.sortList = [{ code: code, order: order }];
            }
            else {
                innerParams.current.sortList = [];
            }
            // 发起远程请求
            fetchCall(false);
        }
        return !!asciiSort;
    };
    // 扩展
    var cellProps = expandCell(props);
    // 执行覆盖操作
    var mergedColumns = handleMerge(props, columns);
    // format
    var formatColumns = cellFormat(mergedColumns, format);
    setInnerInfo(remote === null || remote === void 0 ? void 0 : remote.instance, Object.assign({}, delegateHub, {
        selectKeys: selectKeys,
        setSelectKeys: setSelectKeys,
        treeKeys: treeKeys,
        columns: formatColumns,
        setTreeKeys: setTreeKeys,
        dataSource: dataSource,
        pageInfo: pageRenderInfo,
        selectInfo: storeSelectInfo,
    }));
    var paginationLocale = useNextLocale('Pagination');
    var pickedNativeProps = obj.pickAttrsWith(props, nativePropsReg);
    return (React.createElement(GlobalContext.Provider, { value: global },
        React.createElement("div", __assign({ "data-name": "CnTable", className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-table', 'cn-ui-table', {
                'cn-table-full': fullContainer || (!fullContainer && paging),
                'cn-table-fullscreen': fullScreen,
            }, className), style: getWrapperStyle(props) }, pickedNativeProps),
            isFunction(slot === null || slot === void 0 ? void 0 : slot.statisticsInfo) && (React.createElement("div", { style: { marginBottom: '8px', marginTop: '8px' } }, slot === null || slot === void 0 ? void 0 : slot.statisticsInfo(selectKeys, (_a = storeSelectInfo.current[1]) !== null && _a !== void 0 ? _a : [], dataSource, Object.assign({}, delegateHub, {
                selectKeys: selectKeys,
                setSelectKeys: setSelectKeys,
                treeKeys: treeKeys,
                columns: formatColumns,
                setTreeKeys: setTreeKeys,
                dataSource: dataSource,
                pageInfo: pageRenderInfo,
                selectInfo: storeSelectInfo,
            })))),
            toolbar && (React.createElement(Toolbar, __assign({}, toolbar, { tableInfo: {
                    selectKeys: selectKeys,
                    storeSelectInfo: storeSelectInfo,
                    showSelectedNum: hasSelect && selectType !== 'single',
                    size: size,
                    setSize: setSize,
                    fullScreen: fullScreen,
                    setFullScreen: setFullScreen,
                    zebra: zebra,
                    setZebra: setZebra,
                    columns: columns,
                    setColumns: setColumns,
                    totalSlot: slot === null || slot === void 0 ? void 0 : slot.totalInfo,
                    // total: (pageProps?.total ?? pageRenderInfo.total) as number,
                    total: pageRenderInfo.total,
                } }))), slot === null || slot === void 0 ? void 0 :
            slot.centerInToolAndTable,
            React.createElement(CnLoading, { visible: loading, tip: $i18n.get({ id: 'Loading', dm: '加载中', ns: 'CnForm' }) },
                React.createElement(CnBaseTable, __assign({}, rest, { locale: {
                        operateColumnTitle: $i18n.get({
                            id: '31255239218827264.CNTM',
                            dm: '操作',
                            ns: 'CnTable',
                        }),
                        operateMoreText: $i18n.get({
                            id: 'More',
                            dm: '更多',
                            ns: 'CnTable',
                        }),
                        copyColumnText: $i18n.get({
                            id: 'WholeColumnReplication',
                            dm: '整列复制',
                            ns: 'CnTable',
                        }),
                        copySelectText: $i18n.get({
                            id: 'CopyTheSelectedColumn',
                            dm: '复制选中列',
                            ns: 'CnTable',
                        }),
                        copyTableText: $i18n.get({
                            id: 'CopyTable',
                            dm: '复制表格',
                            ns: 'CnTable',
                        }),
                    }, size: size, style: getFinalStyle(props), columns: formatColumns, dataSource: dataSource, primaryKey: primaryKey, 
                    // isLoading={loading}
                    columnsAutoWidth: columnsAutoWidth, onSort: middleSort, className: tableClassName, sortCustom: __assign(__assign({}, (sortCustom || {})), { sorts: sorts, onChangeSorts: setSorts }) }, (isNil(props.rowDetail)
                    ? null
                    : {
                        rowDetail: __assign(__assign({}, props.rowDetail), { openKeys: openKeys, onChangeOpenKeys: setOpenKeys }),
                    }), { isZebra: zebra, cellProps: cellProps, emptyContent: emptyContent, treeConfig: treeConfig, rowSelection: hasSelect
                        ? __assign(__assign({ type: selectType }, (rowSelection || {})), { value: selectKeys, selectedRowKeys: selectKeys, onChange: setSelectKeys }) : undefined }))),
            React.createElement("div", { className: classNames('cn-table-footer-slot', {
                    'cn-table-footer-slot-display': !paging && !(slot === null || slot === void 0 ? void 0 : slot.centerInPageAndTable),
                }) },
                paging ? (React.createElement("div", { className: "cn-table-page" },
                    React.createElement(CnPagination, __assign({ align: pagingDirection, locale: paginationLocale, size: pageProps === null || pageProps === void 0 ? void 0 : pageProps.size, pageSizeSelector: "dropdown", current: pageRenderInfo.currentPage, pageSize: pageRenderInfo.pageSize, onChange: function (page) {
                            pageRenderInfo.setCurrent(page);
                            fetchCall({ currentPage: page });
                        }, onPageSizeChange: function (pageSize) {
                            pageRenderInfo.setPageSize(pageSize);
                            fetchCall({ currentPage: 1, pageSize: pageSize });
                        }, total: pageRenderInfo.total, pageSizeList: pageRenderInfo.pageSizeList }, (pageProps || {}))))) : null, slot === null || slot === void 0 ? void 0 :
                slot.centerInPageAndTable))));
}
export var CnTable = React.forwardRef(function (props, ref) {
    var _a;
    var pluginIns = useCreatePluginInstance('CnTable', props === null || props === void 0 ? void 0 : props.usePlugin);
    var plugins = (_a = pluginIns === null || pluginIns === void 0 ? void 0 : pluginIns.getPlugin) === null || _a === void 0 ? void 0 : _a.call(pluginIns);
    if (plugins.length === 0) {
        return React.createElement(CnTableOrigin, __assign({}, props, { ref: ref }));
    }
    return HocBaseComponents(CnTableOrigin, { props: props, plugins: plugins });
});
CnTable.displayName = 'CnTable';
CnTable.useRemote = function () {
    var ref = useRef({});
    var fetch = useCallback(function () {
        var _a, _b;
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        // @ts-ignore
        if (isFunction((_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.fetchData)) {
            // @ts-ignore
            return (_b = ref === null || ref === void 0 ? void 0 : ref.current) === null || _b === void 0 ? void 0 : _b.fetchData.apply(_b, args);
        }
    }, []);
    // 请求后，清除分页信息和选中等所有信息
    var refresh = useCallback(function () {
        fetch('refresh');
    }, []);
    // 请求后，仅清除分页信息外的其他所有信息
    var refreshExceptPaging = useCallback(function () {
        fetch('refreshExceptPaging');
    }, []);
    // 请求后，不清楚任何信息
    var load = useCallback(function () {
        return fetch('load');
    }, []);
    var getInnerData = useCallback(function () {
        var _a;
        // @ts-ignore
        return (_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.innerInfo;
    }, []);
    return {
        refreshExceptPaging: refreshExceptPaging,
        refresh: refresh,
        load: load,
        remoteOperation: ref,
        getInnerData: getInnerData,
    };
};
CnTable.useInstance = CnTable.useRemote;
/**
 * @description 创建远程数据实例
 * @returns {Object} 返回包含 refresh, load, remoteOperation 三个方法的对象
 */
CnTable.createRemoteInstance = function () {
    var ref = {
        current: {},
    };
    var fetch = function () {
        var _a, _b;
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        // @ts-ignore
        if (isFunction((_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.fetchData)) {
            // @ts-ignore
            return (_b = ref === null || ref === void 0 ? void 0 : ref.current) === null || _b === void 0 ? void 0 : _b.fetchData.apply(_b, args);
        }
    };
    // 请求后，清除分页信息和选中等所有信息
    var refresh = function () {
        fetch('refresh');
    };
    // 请求后，仅清除分页信息外的其他所有信息
    var refreshExceptPaging = function () {
        fetch('refreshExceptPaging');
    };
    // 请求后，不清楚任何信息
    var load = function () {
        return fetch('load');
    };
    return {
        refresh: refresh,
        load: load,
        refreshExceptPaging: refreshExceptPaging,
        remoteOperation: ref,
    };
};
CnTable.config = config;
CnTable.registerFormat = registerFormat;
CnTable.getCellFormat = getCellFormat;
CnTable.defaultProps = {
    storageKey: function () { return "CNTABLE_".concat(window.location.origin).concat(window.location.pathname); },
};
