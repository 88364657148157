import React, { useCallback, useContext, useState } from 'react';
import { CnDrawer, CnModal } from '@cainiaofe/cn-ui-m';
import { ShellStateContext } from './context';
import { MenuItem } from './menu-item';
export var Side = function () {
    var _a = useContext(ShellStateContext), sideVisible = _a.sideVisible, setSideVisible = _a.setSideVisible, user = _a.user, menu = _a.menu, menuBottomSlot = _a.menuBottomSlot, userSlot = _a.userSlot, userRightSlot = _a.userRightSlot, widgetSlot = _a.widgetSlot;
    var _b = useState(false), userSlotModalVisible = _b[0], setUserSlotModalVisible = _b[1];
    var avatar = user.img ||
        'https://img.alicdn.com/imgextra/i1/O1CN01JkTVma1u660f1TAPV_!!6000000005987-2-tps-112-112.png';
    var onClose = function () {
        setSideVisible(false);
        setUserSlotModalVisible(false);
    };
    var onUserSlotModalClose = useCallback(function () {
        setUserSlotModalVisible(false);
    }, []);
    return (React.createElement(CnDrawer, { placement: "left", width: 270, visible: sideVisible, closeMode: ['esc', 'mask'], onClose: onClose, className: "cn-ui-m-shell-side-wrapper", containerClassName: "cn-ui-m-shell-side", noCard: true },
        React.createElement("div", { className: "cn-ui-m-shell-side-user" },
            React.createElement("div", { className: "cn-ui-m-shell-side-user-avatar", style: { backgroundImage: "url(".concat(avatar, ")") }, onClick: function () { return setUserSlotModalVisible(true); } }),
            React.createElement(CnModal, { placement: "bottom", visible: userSlotModalVisible, onVisibleChange: setUserSlotModalVisible },
                React.createElement("div", { className: "user-overlay" },
                    React.isValidElement(userSlot) && userSlot,
                    typeof userSlot === 'function' ? userSlot(onUserSlotModalClose) : null)),
            user.displayname ? (React.createElement("div", { className: "cn-ui-m-shell-side-user-name" }, user.displayname)) : null,
            userRightSlot ? (React.createElement("div", { className: "cn-ui-m-shell-side-user-right-slot" }, userRightSlot)) : null),
        widgetSlot ? (React.createElement("div", { className: "cn-ui-m-shell-side-widget-slot" }, widgetSlot)) : null,
        React.createElement("ul", { className: "cn-ui-m-shell-side-menu" }, menu.map(function (item) { return (React.createElement(MenuItem, { key: item.key || item.path, data: item, level: 1 })); })),
        menuBottomSlot ? (React.createElement("div", { className: "cn-ui-m-shell-side-bottom-slot" }, menuBottomSlot(onClose))) : null));
};
