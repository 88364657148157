import { __assign, __rest } from "tslib";
import * as React from 'react';
import pick from 'lodash/pick';
import { CnReadOnly } from "../cn-read-only";
import { useDerivedValue } from "../cn-utils";
export function nextCompMap(Comp, ComponentName) {
    var Component = React.forwardRef(function (props, ref) {
        var readOnly = props.readOnly, readOnlyProps = props.readOnlyProps, otherProps = __rest(props, ["readOnly", "readOnlyProps"]);
        var _a = useDerivedValue(props, undefined), value = _a[0], setValue = _a[1], isControlled = _a[2];
        var onChange = function (newValue, e) {
            if (!isControlled) {
                setValue(newValue);
            }
            props.onChange && props.onChange(newValue, e);
        };
        if (readOnly) {
            return (React.createElement(CnReadOnly, __assign({ value: value }, __assign(__assign({}, pick(props, [
                'addonBefore',
                'innerBefore',
                'addonTextBefore',
                'addonTextAfter',
                'innerAfter',
                'addonAfter',
            ])), readOnlyProps))));
        }
        return (React.createElement(Comp, __assign({ "data-name": "CnInput", ref: ref }, otherProps, { value: value, onChange: onChange })));
    });
    Component.displayName = ComponentName;
    return Component;
}
