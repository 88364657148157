import dayjs from 'dayjs';
import $i18n, { pandaEvent, langToShortLang } from 'panda-i18n';
import { datejs } from "../../../_fusion/lib/util/index";
import "../../locales/dayjs";
// 基于标准语种返回dayjs的国际化语种
var getDayjsLang = function (lang) {
    var slang = langToShortLang(lang);
    // 兼容希伯来语(panda-i18n对于希伯来语可能存在2种版本的，dayjs只提供的he的翻译)
    slang = slang === 'iw' ? 'he' : slang;
    // 兼容英文本地语种，为了提高英文本地语种的兼容性，所有英文本地语种全部fallback到en
    slang = slang.startsWith('en-') ? 'en' : slang;
    // 不再支持范围的默认为英文
    return dayjs.Ls[slang] ? slang : 'en';
};
var changeDayjsLocale = function (locale) {
    dayjs.locale(locale);
    datejs.locale(locale);
};
changeDayjsLocale(getDayjsLang($i18n.getLang()));
pandaEvent.on('CHANGE_LANG', function () {
    changeDayjsLocale(getDayjsLang($i18n.getLang()));
});
export { dayjs };
