import { __assign } from "tslib";
import './cn-card.scss';
import { useI18n } from 'panda-i18n';
import React from 'react';
import { CnTag } from "../cn-tag";
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import { useControllableValue } from 'ahooks';
import { CnIcon } from "../cn-icon";
import { CnTooltip } from "../cn-tooltip";
import { CnCardSubCard } from './sub-card';
import { CnLoading } from "../cn-loading";
import { withNativeProps } from '@cainiaofe/cn-ui-common';
export function CnCardView(props) {
    var _a;
    var id = props.id, className = props.className, icon = props.icon, title = props.title, titleTips = props.titleTips, titleTag = props.titleTag, children = props.children, desc = props.desc, action = props.action, foldable = props.foldable, style = props.style, loading = props.loading, shape = props.shape;
    var noPadding = props.noPadding, noShadow = props.noShadow;
    if (shape === 'group') {
        noPadding = true;
        noShadow = true;
    }
    var $i18n = useI18n();
    var _b = useControllableValue(props, {
        valuePropName: 'fold',
        defaultValuePropName: 'initFold',
        trigger: 'onFoldChange',
    }), fold = _b[0], setFold = _b[1];
    var hasCardHeader = title || icon || titleTips || (titleTag === null || titleTag === void 0 ? void 0 : titleTag.children) || action || foldable || desc;
    return withNativeProps(props, React.createElement("div", { id: id, "data-name": "CnCard", className: classNames([
            CN_UI_HASH_CLASS_NAME,
            className,
            'cn-card',
            'cn-ui-card',
            fold ? 'cn-ui-card-fold' : 'cn-ui-card-unfold',
            title ? 'has-title' : 'no-title',
            "cn-ui-card-".concat(shape),
            noPadding ? 'cn-ui-card-no-padding' : 'cn-ui-card-has-padding',
            noShadow ? 'cn-ui-card-no-shadow' : 'cn-ui-card-has-shadow',
            {
                'cn-ui-card-no-header': !hasCardHeader,
            },
        ]), style: style },
        hasCardHeader && (React.createElement("div", { className: classNames((_a = {
                    'cn-ui-card-header': true
                },
                _a["cn-ui-card-header-".concat(shape)] = shape,
                _a['cn-ui-card-header-no-padding'] = noPadding,
                _a)) },
            React.createElement("div", { className: "cn-ui-card-header-content" },
                React.createElement("div", { className: "cn-ui-card-header-title-container" },
                    icon || shape === 'primary' ? (React.createElement("div", { className: classNames({
                            'cn-ui-card-header-icon-wrapper': true,
                            'cn-ui-card-header-icon-wrapper-primary': shape === 'primary',
                        }) },
                        React.createElement(CnIcon, { size: "medium", className: "cn-ui-card-header-icon", 
                            // 兼容存量业务默认 icon
                            type: icon || (shape === 'primary' ? 'service-center' : null) }))) : null,
                    React.createElement("div", { className: "cn-ui-card-header-title" }, title),
                    titleTips && (React.createElement("div", { className: "cn-ui-card-header-tips" },
                        React.createElement(CnTooltip, { trigger: React.createElement(CnIcon, { size: "medium", className: "cn-ui-card-tips-icon", type: "help" }), triggerType: "hover", align: "t" }, titleTips))),
                    titleTag && (titleTag === null || titleTag === void 0 ? void 0 : titleTag.children) && (React.createElement(CnTag, __assign({ className: "cn-ui-card-header-tag" }, titleTag)))),
                React.createElement("div", { className: "cn-ui-card-header-action" },
                    action,
                    foldable && (React.createElement("div", { className: "cn-ui-card-header-fold", onClick: function () { return setFold(!fold); } },
                        fold
                            ? $i18n.get({ id: 'Expand', dm: '展开' })
                            : $i18n.get({ id: 'PutItAway', dm: '收起' }),
                        React.createElement(CnIcon, { size: "medium", type: !fold ? 'triangle-up-fill' : 'triangle-down-fill' }))))),
            desc && (React.createElement("div", { className: "cn-ui-card-desc", style: { display: fold ? 'none' : 'block' } }, desc)))),
        React.createElement("div", { className: classNames('cn-ui-card-content', {
                'cn-card__content--with-gap': props.contentGap,
                'cn-ui-card__content--no-padding': noPadding,
                'cn-ui-card__content--no-padding--no-header': noPadding && !hasCardHeader,
            }), style: { display: fold ? 'none' : null } }, children && (React.createElement("div", { className: "cn-ui-card-container" }, isNil(loading) ? (children) : (React.createElement(CnLoading, { visible: loading, style: { display: 'block' }, size: "medium" }, children)))))));
}
CnCardView.displayName = 'CnCard';
CnCardView.defaultProps = {
    foldable: false,
    initFold: false,
    contentGap: true,
    // 兼容存量业务
    shape: 'primary',
};
// header, subtitle组件暴露出来供单独使用
CnCardView.SubTitle = CnCardSubCard;
